import tw, { css, styled } from "twin.macro";
import Img from "gatsby-image";
import Link from "../components/BaseLink";
import { Field, ErrorMessage } from "formik";
import Pattern from "../img/bg-pattern.png";

export const SR = tw.span`
  sr-only
`;

export const H1 = tw.h1`
  block relative text-4xl leading-tight tracking-tight text-white sm:text-4xl max-w-2xl lg:max-w-3xl md:text-6xl font-light mx-auto mb-8
`;

export const H2 = tw.h2`
  relative block text-3xl leading-tight tracking-tight text-white text-center sm:text-4xl max-w-xl lg:max-w-3xl lg:text-5xl lg:leading-tight font-light mx-auto mb-8
`;

export const H3 = tw.h3`
  relative block text-4xl font-light text-fire mb-4 leading-tight
`;

export const H1A = tw.h1`
  block relative text-4xl leading-tight tracking-tight sm:text-4xl max-w-2xl lg:max-w-3xl md:text-6xl font-light mx-auto mb-8
`;

export const H2A = tw.h2`
  relative block text-3xl leading-tight tracking-tight sm:text-4xl max-w-xl lg:max-w-3xl lg:text-5xl lg:leading-tight font-light mx-auto mb-8
`;

export const H3A = tw.h3`
  relative block text-4xl font-light mb-4 leading-tight
`;

export const H4 = tw.h4`
  relative block font-semibold text-xl mt-6 mb-1
`;

export const H5 = tw.h5`
  relative block text-lg text-fire mt-4 mb-1
`;

export const H6 = tw.h6`
  relative block font-bold mb-2
`;

export const P = styled.p`
  ${tw`relative block text-base mb-2`}
`;

export const Button2 = styled.button``

export const Ul = tw.ul`
  list-disc pl-6 text-base my-2
`;

export const Ol = tw.ol`
  list-decimal pl-6 text-base my-2
`;

export const Li = tw.li`
  my-2 pl-2
`;

export const Button = tw(Link)`
  bg-transparent border border-white inline-flex items-center justify-center px-5 py-3 leading-6 font-medium rounded-md text-white focus:shadow-outline transition duration-150 ease-in-out
`;

export const Input = tw.input`
  border border-2 border-ink rounded-md px-4 py-3 text-ink
`;

export const FormMsg = styled.div`
  ${tw`absolute text-fire -mt-3 mb-3`}
  ${(props) => props.light && tw`text-red-400`}
`;

export const BevelBorder = styled.div`
  border: 1px solid #7f87e2;
  border-image-source: url("/assets/img/bevel-border${(props) =>
    props.white && "-linen"}.svg");
  border-image-slice: 12 12;
  border-image-width: 12;
`;

export const soloBorder = css`
  border: 1px solid #7f87e2;
  border-image-source: url("/assets/img/bevel-border-linen.svg");
  border-image-slice: 12 12;
  border-image-width: 12;
`;

export const BorderButton = styled(Link)`
  border: 1px solid #7f87e2;
  border-image-source: url("/assets/img/bevel-border-linen.svg");
  border-image-slice: 12 12;
  border-image-width: 12;
  ${tw`relative z-20 inline-flex mt-6 transition duration-150 ease-in-out uppercase text-center px-5 py-3 font-medium text-base tracking-wide text-white`}
  ${(props) => (props.center ? tw`mx-auto` : tw``)}
  &:hover {
    border-image-source: url("/assets/img/bevel-border.svg");
  }
`;

export const SolidButton = styled(Link)`
  clip-path: polygon(
    8px 0%,
    calc(100% - 8px) 0%,
    100% 8px,
    100% calc(100% - 8px),
    calc(100% - 8px) 100%,
    8px 100%,
    0% calc(100% - 8px),
    0% 8px
  );
  ${tw`relative mt-6 z-20 inline-flex px-4 py-2 md:px-5 md:py-3 border border-transparent text-sm md:text-base font-medium uppercase tracking-wide focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 hover:(text-white bg-fire cursor-pointer)`}
  ${(props) => (props.center ? tw`mx-auto` : tw``)}
  ${(props) => (props.white ? tw`bg-white text-ink` : tw`bg-ink text-white`)}
  ${(props) =>
    props.notActive
      ? tw`hover:(cursor-not-allowed bg-white text-ink) opacity-50`
      : tw``}
`;

export const SolidFireButton = styled(Link)`
  clip-path: polygon(
    8px 0%,
    calc(100% - 8px) 0%,
    100% 8px,
    100% calc(100% - 8px),
    calc(100% - 8px) 100%,
    8px 100%,
    0% calc(100% - 8px),
    0% 8px
  );
  ${tw`relative mt-6 z-20 inline-flex px-4 py-2 md:px-5 md:py-3 border border-transparent text-sm md:text-base font-medium uppercase tracking-wide focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-fire transition ease-in-out duration-150 text-white bg-fire cursor-pointer hover:(text-white bg-fire cursor-pointer)`}
`;

export const CutCorner = styled.div`
  position: relative;
  background-color: #222761;
  z-index: 2;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% calc(100% - 33px),
    calc(100% - 33px) 100%,
    0% 100%
  );
  ${(props) =>
    props.shadow &&
    css`
      clip-path: none;
      border-bottom-right-radius: 15px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 1rem;
        left: 1rem;
        bottom: -1rem;
        right: -1rem;
        background-color: #ed4c40;
        z-index: -1;
        clip-path: polygon(
          calc(100% - 1rem) 0,
          100% 0,
          100% calc(100% - 40px),
          calc(100% - 40px) 100%,
          0 100%,
          0 calc(100% - 1rem),
          calc(100% - 1rem - 33px) calc(100% - 1rem),
          calc(100% - 1rem) calc(100% - 1rem - 33px)
        );
      }
    `}
`;

export const clipPath = {
  lg: css`
    polygon(
      0% 0%,
      100% 0%,
      100% calc(100% - 33px),
      calc(100% - 33px) 100%,
      0% 100%
    );
  `,
  md: css`
    polygon(
      0% 0%,
      100% 0%,
      100% calc(100% - 24px),
      calc(100% - 24px) 100%,
      0% 100%
    );
  `,
  sm: css`
    polygon(
      0% 0%,
      100% 0%,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0% 100%
    );
  `,
  bevels: css`
  polygon(
    18px 0%,
    calc(100% - 18px) 0%,
    100% 18px,
    100% calc(100% - 18px),
    calc(100% - 18px) 100%,
    18px 100%,
    0% calc(100% - 18px),
    0% 18px
  );
  `,
};

export const clipShadow = {
  lg: css`
    clip-path: none;
    border-bottom-right-radius: 15px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 1rem;
      left: 1rem;
      bottom: -1rem;
      right: -1rem;
      background-color: ${(props) => (props.bg ? props.bg : "#222761")};
      z-index: -1;
      clip-path: polygon(
        calc(100% - 1rem) 0,
        100% 0,
        100% calc(100% - 40px),
        calc(100% - 40px) 100%,
        0 100%,
        0 calc(100% - 1rem),
        calc(100% - 1rem - 33px) calc(100% - 1rem),
        calc(100% - 1rem) calc(100% - 1rem - 33px)
      );
    }
  `,
  md: css`
    clip-path: none;
    border-bottom-right-radius: 10px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 1rem;
      left: 1rem;
      bottom: -1rem;
      right: -1rem;
      background-color: ${(props) => (props.bg ? props.bg : "#222761")};
      z-index: -1;
      clip-path: polygon(
        calc(100% - 1rem) 0,
        100% 0,
        100% calc(100% - 30px),
        calc(100% - 30px) 100%,
        0 100%,
        0 calc(100% - 1rem),
        calc(100% - 1rem - 24px) calc(100% - 1rem),
        calc(100% - 1rem) calc(100% - 1rem - 24px)
      );
    }
  `,
  sm: css`
    clip-path: none;
    border-bottom-right-radius: 15px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      bottom: -0.5rem;
      right: -0.5rem;
      background-color: ${(props) => (props.bg ? props.bg : "#222761")};
      z-index: -1;
      clip-path: polygon(
        calc(100% - 0.5rem) 0,
        100% 0,
        100% calc(100% - 12px),
        calc(100% - 12px) 100%,
        0 100%,
        0 calc(100% - 0.5rem),
        calc(100% - 0.5rem - 8px) calc(100% - 0.5rem),
        calc(100% - 0.5rem) calc(100% - 0.5rem - 8px)
      );
    }
  `,
};

export const CutCornerBorder = styled(CutCorner)`
  ${tw`border border-ink`}
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 1;
    clip-path: polygon(
      0.5px 0.5px,
      calc(100% - 0.5px) 0.5px,
      calc(100% - 0.5px) calc(100% - 33px),
      calc(100% - 33px) calc(100% - 0.5px),
      0.5px calc(100% - 0.5px)
    );
  }
`;

// Helper functions below

export const arabicToRoman = (number) => {
  let roman = "";
  const romanNumList = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XV: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let a;
  if (number < 1 || number > 3999) return "Enter a number between 1 and 3999";
  else {
    for (let key in romanNumList) {
      a = Math.floor(number / romanNumList[key]);
      if (a >= 0) {
        for (let i = 0; i < a; i++) {
          roman += key;
        }
      }
      number = number % romanNumList[key];
    }
  }
  return roman;
};

export const FormField = tw(Field)`
  py-1 text-white border-0 border-b border-linen bg-transparent focus:border-white appearance-none placeholder-linen
`;

export const FormError = tw(ErrorMessage)`
  text-red-400 absolute bottom-0 right-0 text-sm
`;

export const FormLabel = tw.label`
  uppercase tracking-widest text-sm pt-2 order-last text-left flex flex-row
`;

export const FormCheckbox = styled(Field)`
  ${tw`appearance-none relative block w-2 h-2 p-3 mr-4 mt-3 border border-white bg-transparent`}
  &:checked:after {
    ${tw`bg-white text-ink absolute inset-0 text-xl text-center leading-none`}
    content: "✓";
  }
`;

export const BGOverlay = styled.div`
  ${tw`absolute w-full h-full inset-0 z-10 bg-black opacity-75`}
  background-image: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(34,39,97,0.7) 100%), url("${Pattern}");
  background-repeat: repeat;
`;

export const BGImage = styled.img`
  ${tw`absolute w-full h-full inset-0 object-cover z-0`}
`;

export const BGImageFluid = styled(Img)`
  ${tw`absolute w-full h-full inset-0 object-cover z-0`}
`;
